// Igni Gallery
.file-widget {
  .file-list {
    li {
      list-style: none;
      .image-item {
        padding: 10px;
      }
      .gallery-image, .gallery-video {
        text-align: center;
        background: #ffffff;
      }
    }
  }
}