#data-table_wrapper {
  position: relative;
  & > a.btn-success {
    position: absolute;
    top: 2px;
    z-index: 1;
  }
  .dataTables_filter {
    margin-bottom: 10px;
    label {
      border: 1px solid #e9e9e9;
      border-radius: 45px;
    }
    .search-label {
      color: #9d9d9d;
      letter-spacing: 0.9px;
      padding: 12px 22px;
    }
    input {
      background-color: #f2f2f2;
      border-radius: 40px;
    }
  }
  .table-bordered {
    border: $table-border;
    border-collapse: collapse !important;
    border-spacing: 0;
  }
  table.dataTable {
    @extend .table-bordered;
    & tfoot th,
    & thead th {
      font-weight: normal;
      text-transform: uppercase;
    }
    & tbody tr {
      color: $text-color-light;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 18px;
      td {
        vertical-align: middle;
      }
    }
    & .action-btns {
      display: flex;
      justify-content: space-around;
      a {
        text-transform: uppercase;
      }
    }
  }
  .table-bordered {
    & > tfoot > tr > td,
    & > tfoot > tr > th {
      border-bottom: $table-border;
    }
    & > tbody > tr > td,
    & > tbody > tr > th,
    & > thead > tr > td,
    & > thead > tr > th {
      border-left: $table-border;
      border-right: $table-border;
    }
  }
  .actions-col {
    width: 210px;
  }
  .table-striped > tbody > tr:nth-of-type(even) {
    background-color: #f2f2f2;
  }
  .dataTables_paginate {
    margin-top: 10px;
    .pagination {
      & > li {
        &.next,
        &.previous {
          & > a,
          & > span {
            font-size: 12px;
            letter-spacing: 0.8px;
            padding: 9px 15px;
          }
        }
        & > a,
        & > span {
          border: none;
          border-radius: 32px;
          color: $text-color-light;
          margin: 0 3px;
          text-transform: uppercase;
        }
      }
      .paginate_button.disabled {
        opacity: 0.7;
      }
      & > .active {
        & > a,
        & > span {
          &,
          &:focus,
          &:hover {
            background-color: $btn-primary-color;
            color: #fff;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: right !important;
  }
}
