body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'SourceSansPro-regular';
}

.uppercase {
  text-transform: uppercase;
}

input.form-control {
  height: 40px;
}

.error-message {
  color: #dd4b39;
  font-size: 12px;
  padding: 5px 0 0 20px;
}

.btn {
  &,
  &.btn-flat {
    border-radius: 40px;
    height: 40px;
    min-width: 70px;
    padding: 12px 16px;
  }
  &:hover {
    opacity: 0.8;
  }
  &:not(.btn-outline) {
    border: none;
  }
  &.btn-outline {
    border: 2px solid #fff;
    padding: 10px 15px;
  }
}

.btn-primary {
  &,
  &:active,
  &:focus,
  &:hover {
    background-color: $btn-primary-color;
  }
}

.btn-success {
  &,
  &:active,
  &:focus,
  &:hover {
    background-color: $btn-success-color;
  }
}

.btn-danger {
  &,
  &:active,
  &:focus,
  &:hover {
    background-color: $btn-danger-color;
  }
}
