.login-page,
.register-page {
  background-color: #e5e6ea;
  .login-logo {
    .admin-logo {
      margin-bottom: 14px;
    }
    h4 {
      font-family: 'SourceSansPro-light';
      letter-spacing: 1.6px;
    }
  }
}

.login-box-body,
.register-box-body {
  background: none;
  input.form-control {
    border: none;
    border-radius: 40px;
  }
}

.btn {
  font-size: 13px;
}

input[type=checkbox] {
  &.css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    & + label.css-label::before {
      padding-left: 32px;
      height: 32px;
      display: inline-block;
      line-height: 32px;
      background-repeat: no-repeat;
      background-position: 0 0;
      font-weight: normal;
      vertical-align: bottom;
      margin-right: 10px;
      margin-top: 25px;
    }
    &:checked + label.css-label::before {
      background-position: -32px 0;
    }
  }
}

label {
  &.css-label {
    cursor: pointer;
    font-weight: normal;
    line-height: 32px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &::before {
      content: " ";
      background-image: url("../images/checkbox.png");
    }
  }
}

.login-link {
  text-align: center;
}
